import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Aviso, Span } from "./styles";

const proyecto = [
  {
    proyectoEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto left pb-5" md="8">
            <h2 className="title">Condominio Privado Río Gamboa</h2>
            <h5 className="description">
              El río Gamboa es un curso natural de agua que fluye en la isla de Chiloé, en la
              región chilena de Los Lagos y desemboca en la ciudad de Castro (Chile).
              Nace en los cerros selvosos de la parte central de la isla de Chiloé, corre hacia el este por una
              quebrada profunda, pasa al pie sur de la meseta en que está asentada la ciudad de Castro y se
              vacía en amplio estuario que llena la marea. Es de corto curso y caudal con ancho Le dió el nombre
              el fundador de Castro, Don Martín Ruiz de Gamboa.
            </h5>
            <h5 className="description">
              Inserto en la ciudad de Castro, parte del destino turístico de la Isla de Chileo encierra
              infinidad de ambientes, los cuales son ideales para aventurarse en sus prístinas
              extensiones. 
            </h5>
            <h5 className="description">
              Explorar montañas, costas, cuencas, aguas termales, lagos, ríos y humedales costeros es
              descubrir una majestuosa naturaleza donde la práctica de deportes extremos es una
              tentación permanente.
            </h5>
            <h5 className="description">
              Actividades como trekking, observación de flora y fauna, cabalgatas, rafting, kayak de río y
              mar, travesías y paseos náuticos son algunas de las alternativas de realizar en este
              territorio.
            </h5>
            <h5 className="description">
              Su patrimonio humano vivo se destaca dentro de la cultura local a través de las recolectoras
              de orilla, los tejueleros del alerce y los carpinteros de ribera. La gastronomía, la artesanía y
              las fiestas costumbristas permiten vivir y conocer las auténticas raíces y tradiciones de
              Chiloé.
            </h5>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    proyectoEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center pb-5" md="8">
            <h2 className="title">
              Fundo Santa Bárbara Condominium/ Gated Community
            </h2>
            <h5 className="description">
              This unique and exclusive project is located in the Northern
              Patagonia of Chile. One of a kind in its natural beauty and
              surrounded by national parks, with direct access to the Austral
              Highway / Route 7. Located 8 km North of Chaitén, this territory
              is delimited on its southern side by an estuary, and where the
              beauty of a small waterfall (common area) stands out. It flows
              into the sea next to caves, small beaches, and a rockery of
              ​​about 500 meters of coastline (Pacific Ocean).
            </h5>
            <h5 className="description">
              The terrane is located in an environmentally protected area,
              inserted in an Evergreen forest. Its rainy climate provides many
              abundant, pure water sources.
            </h5>
            <h5 className="description">
              The project is oriented towards those seeking nature conservation
              and tourism, nature walks, trekking, outdoor activities, nature
              study, amongst others. The sights really leave you at awe, with
              the majesty of its views and untamed nature. All of this added to
              the kindness and caring spirit of its surrounding community of
              locals/ residents. The population of this area is only 1.2
              inhabitants per km2.
            </h5>
            <h5 className="description">
              Construction options are vast, offering numerous building choices
              that can even include a rustic cabin made from native wood and
              fine finishes with local flagstone. The sky is the limit!
            </h5>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    caracteristicasEs: (
      <Container>
        <Row>
          <div className="ml-auto mr-auto">
            <h2 className="title text-center">Características</h2>
          </div>
        </Row>
        <Row>
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-i-lock" />
              </div>
              <h4 className="title">Seguridad</h4>
              <p>
                Acceso controlado
              </p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-water-hand" />
              </div>
              <h4 className="title">Urbanización</h4>
              <p>
                Parcelas cuentan con agua potable y electricidad, en modalidad de condomino con caminos
                interiores construidos
              </p>
            </div>
          </Col>
        </Row>
        <Row className="bottom-line">
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-park" />
              </div>
              <h4 className="title">Áreas Comunes</h4>
              <p>
                
              </p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">
            {/* <Aviso>
              <Span>En Proyecto</Span>
            </Aviso> */}
            <div className="info">
              <div className="icon">
                <i
                  aria-hidden={true}
                  className="nc-icon icon-rowing"
                />
              </div>
              <h4 className="title">Playa de río</h4>
              <p>
                destinada para uso común
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    caracteristicasEn: (
      <Container>
        <Row>
          <div className="ml-auto mr-auto">
            <h2 className="title text-center">Characteristics</h2>
          </div>
        </Row>
        <Row>
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-i-lock" />
              </div>
              <h4 className="title">Security</h4>
              <p>Controlled access - electric gate - security cameras.</p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-water-hand" />
              </div>
              <h4 className="title">Housing development</h4>
              <p>
                Lots include: Electricity, Water connection to tap / drinking
                water
              </p>
            </div>
          </Col>
        </Row>
        <Row className="bottom-line">
          <Col className="ml-auto" sm="5">
            <div className="info">
              <div className="icon">
                <i aria-hidden={true} className="nc-icon icon-park" />
              </div>
              <h4 className="title">Common areas/ grounds</h4>
              <p>
                Matting edge facing the sea, trails, barbecue area and
                playground areas next to a small waterfall.
              </p>
            </div>
          </Col>
          <Col className="mr-auto" sm="5">
            <Aviso>
              <Span>In Project</Span>
            </Aviso>
            <div className="info">
              <div className="icon">
                <i
                  aria-hidden={true}
                  className="nc-icon icon-boat-small-02-2"
                />
              </div>
              <h4 className="title">Beach access</h4>
              <p>Owners will have access to the beach front Marina.</p>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    florayfaunaEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Flora y Fauna</h2>
          </Col>
        </Row>
        <Row className="pt-md-5">
          <Col md="6">
            <div className="ipad-container">
              <img alt="..." src={require("assets/images/sections/fyf.jpg")} />
            </div>
          </Col>
          <Col md="6">
            <div className="info info-horizontal pt-md-0">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-tree-01" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Flora</h4>
                <p>
                  Se pueden encontrar especies arbóreas endémicas como el arrayán, 
                  el roble, el coigüe y el avellano.En las alturas de la Cordillera de la Costa 
                  que pasa por Chiloé están los tepúes y los alerces: estos últimos, 
                  gigantes de la naturaleza que alcanzan hasta 3.500 años de edad, 
                  siendo considerados los árboles más antiguos de Sudamérica y los segundos más longevos del mundo. 
                  Dentro de la flora de menor tamaño, destaca la papa y sus cerca de 400 variedades. 
                  Otras plantas nativas de Chiloé son la nalca, la frutilla de arena, el carrizo, el chupón, 
                  la murta y los calafates.
                </p>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-deer" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Fauna</h4>
                <p>
                  Zorros, pudúes, monitos del monte, guiña (o gatos colo) y comadrejas trompudas son los
                  animales chilotes que sacan la cara por los mamíferos.
                  Por el lado de las aves, la bandurria, la golondrina, el picaflor, el canquén, el queltehue, la
                  bandurria, la diuca, el churrete y el chincol son ilustres exponentes, aunque existen muchos
                  más.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    florayfaunaEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Flora and Fauna</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ipad-container">
              <img alt="..." src={require("assets/images/sections/fyf.jpg")} />
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-tree-01" />
              </div>
              <div className="description">
                <h4 className="info-title">Flora</h4>
                <p>
                  One of the icons of this area is the Larch tree, that can live
                  for many years and grow to more than 50 meters. Other commonly
                  seen tree species are: Lenga, Coigüe, Ñirre, Canelo, Mañío,
                  Luma, Tepa, Ulmo, ciruelillo, amongst others. The presence of
                  flowers like the Copihues (national flower) is characteristic
                  of its forests.
                </p>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-deer" />
              </div>
              <div className="description">
                <h4 className="info-title">Fauna</h4>
                <p>
                  You can frequently see dolphins, cormorants, penguins, herons
                  and kingfishers in the sea. In the Valdivian rainforests you
                  can go for bird-walks to catch sight of a wide variety of
                  birds such as chucao, hued-hued and rayadito. From the mammal
                  family and not so easy to encounter, are animals such as the
                  pudu, fox, colocolo cat, wink, cougar and an unusual
                  marsupial.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    actividadesEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Actividades</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-walk" />
              </div>
              <div className="description">
                <h4 className="info-title">Senderismo</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-mountain" />
              </div>
              <div className="description">
                <h4 className="info-title">Montañismo</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-horse" />
              </div>
              <div className="description">
                <h4 className="info-title">Cabalgatas</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-rowing" />
              </div>
              <div className="description">
                <h4 className="info-title">Kayak</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-surface-2" />
              </div>
              <div className="description">
                <h4 className="info-title">Rafting</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-fish" />
              </div>
              <div className="description">
                <h4 className="info-title">Pesca Deportiva</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-binocular" />
              </div>
              <div className="description">
                <h4 className="info-title">Avistamiento de Aves</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-aerobics" />
              </div>
              <div className="description">
                <h4 className="info-title">Termas</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-shop-location" />
              </div>
              <div className="description">
                <h4 className="info-title">Gastronomía local</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    actividadesEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Activities</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-walk" />
              </div>
              <div className="description">
                <h4 className="info-title">Trekking</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-mountain" />
              </div>
              <div className="description">
                <h4 className="info-title">Climbing</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-horse" />
              </div>
              <div className="description">
                <h4 className="info-title">Horseback riding</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-rowing" />
              </div>
              <div className="description">
                <h4 className="info-title">Kayaking</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-surface-2" />
              </div>
              <div className="description">
                <h4 className="info-title">Rafting</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-fish" />
              </div>
              <div className="description">
                <h4 className="info-title">Fly fishing and fishing</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-binocular" />
              </div>
              <div className="description">
                <h4 className="info-title">Bird Watching</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-water-aerobics" />
              </div>
              <div className="description">
                <h4 className="info-title">Soaking in hot springs</h4>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon icon-search" />
              </div>
              <div className="description">
                <h4 className="info-title">Geo-tourism</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
];

function Proyecto(props) {
  const renderProyecto = () => {
    if (props.idioma === "esp") {
      return proyecto[0].proyectoEs;
    } else {
      return proyecto[1].proyectoEn;
    }
  };
  const renderCaracteristicas = () => {
    if (props.idioma === "esp") {
      return proyecto[2].caracteristicasEs;
    } else {
      return proyecto[3].caracteristicasEn;
    }
  };
  const renderFloraFauna = () => {
    if (props.idioma === "esp") {
      return proyecto[4].florayfaunaEs;
    } else {
      return proyecto[5].florayfaunaEn;
    }
  };
  const renderActividades = () => {
    if (props.idioma === "esp") {
      return proyecto[6].actividadesEs;
    } else {
      return proyecto[7].actividadesEn;
    }
  };
  return (
    <>
      <div className="section section-feature cd-section">
        <div className="features-1" id="condominio"></div>
        {renderProyecto()}
        <div
          id="caracteristicas"
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/sections/caracteristicas.jpg") +
              ")",
          }}
        >
          {renderCaracteristicas()}
        </div>
        <div id="florayfauna" className="features-4 pb-0">
          {renderFloraFauna()}
        </div>
        <div id="actividades" className="features-1">
          {renderActividades()}
        </div>
      </div>
    </>
  );
}
export default Proyecto;
