import React from 'react'
import { GoogleMap, LoadScript, KmlLayer } from "@react-google-maps/api";

const containerStyle = {
	width: "100%",
	height: "520px",
	maxHeight: "520px",
};

const center = { lat: -42.47275, lng: -73.79402 };
function Map() {
	const generateRandom = () => {
		return Math.random() * 10000000000000000;
	};
	return (
		<LoadScript googleMapsApiKey="AIzaSyBX-fT0cZLTKydQN_HFfeCfHKq_Um_4cSQ">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={11}
				mapTypeId={"hybrid"}
			>
				<KmlLayer
					url={`https://www.google.com/maps/d/u/0/kml?mid=1n69gkiOzb1Ha-NmsrrVAnnCk6gtG5YNd&ver=${generateRandom()}`}
					opciones={{ preserveViewport: true }}
				/>
			</GoogleMap>
		</LoadScript>
	);
}
export default Map